/* eslint-disable react/function-component-definition */
import { gql, useMutation, useQuery } from "@apollo/client";
import { MenuItem, TextField } from "@mui/material";
import { CheckCircleOutlineRounded } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import tw from "twin.macro";

import {
  JimButton,
  JimDialog,
  LoadingDialog,
  TilePrim,
} from "../components/util-components";
import { PageTitleContext } from "../utils/pagetitle-provider";
import withRoles from "../utils/with-roles";

const LIST_FACILITIES = gql`
  query ListFacilities {
    listFacilities {
      id
      name
    }
  }
`;

const CREATE_JOB = gql`
  mutation CreateJob($title: String!, $facility: String!, $sugg_descr: String) {
    createJob(title: $title, facility: $facility, sugg_descr: $sugg_descr) {
      id
      title
      facility {
        id
      }
      sugg_descr
    }
  }
`;

const AdminCreateJob = () => {
  const { setPageTitle } = useContext(PageTitleContext);
  useEffect(() => setPageTitle("Job erstellen"), []);
  const [jobTitle, setJobTitle] = useState(null);
  const [facilityID, setFacilityID] = useState("1");
  const [jobDescr, setJobDescr] = useState(null);
  const { data: listFacilitiesData } = useQuery(LIST_FACILITIES);
  const [createJob, { loading: createJobLoading }] = useMutation(CREATE_JOB);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    if (facilityID !== "1") {
      createJob({
        variables: {
          title: jobTitle,
          facility: facilityID,
          sugg_descr: jobDescr,
        },
      }).then(() => setShowSuccessDialog(true));
    }
  };

  return (
    <>
      <TilePrim>
        <form
          tw="flex flex-col space-y-4"
          id="create-job-form"
          onSubmit={handleSubmit}
        >
          <TextField
            id="job-title-input"
            value={jobTitle}
            required
            label="Titel"
            variant="outlined"
            onChange={e => setJobTitle(e.target.value)}
          />
          <TextField
            id="select-facility"
            value={facilityID}
            select
            required
            label="Author"
            variant="outlined"
            onChange={e => setFacilityID(e.target.value)}
          >
            <MenuItem value="1">auswählen...</MenuItem>
            {listFacilitiesData &&
              listFacilitiesData.listFacilities[0] &&
              listFacilitiesData.listFacilities.map(fac => (
                <MenuItem key={fac.id} value={fac.id}>
                  {fac.name}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            id="job-descr-input"
            value={jobDescr}
            multiline
            rows={4}
            label="Beschreibung"
            variant="outlined"
            onChange={e => setJobDescr(e.target.value)}
          />
          <JimButton variant="primary" type="submit" form="create-job-form">
            Job erstellen
          </JimButton>
        </form>
      </TilePrim>
      <JimDialog
        open={showSuccessDialog}
        handleClose={() => setShowSuccessDialog(false)}
        tw="flex flex-col justify-center items-center space-y-4 w-56"
      >
        <CheckCircleOutlineRounded tw="flex text-6xl text-green-600" />
      </JimDialog>
      <LoadingDialog open={createJobLoading} />
    </>
  );
};

export default withRoles(AdminCreateJob, "ADMIN");
